import { Box, Button, Typography, CircularProgress } from "@mui/material";
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { PropsWithChildren, useEffect } from "react";
import { fetchCurrentUser, fetchLicense, selectCurrentUser, fetchModelConfigs } from "../../store/userSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const PageContainerBoxStyle = { 
  height: "100%", 
  display: "flex",
  flexDirection: "column", 
  gap: "32px",
  overflow: "hidden",
  padding: "32px 32px 32px 32px"
};

const ButtonsBarBoxStyle = {
  width: "100%", 
  height: "40px", 
  display: "flex", 
  justifyContent: "space-between", 
  gap: 4, 
  alignItems: "center"
};

type PageContentButtonProps = {
  handler?: () => void;
  disabled?: boolean;
  text?: string;
  hide?: boolean;
  loading?: boolean;
  downloadVariant?: boolean;
};

type PageContentProps = PropsWithChildren & {
  title?: string;
  subtitle?: string;
  showButtonBar?: boolean;
  currentProgress?: string;
  nextButton?: PageContentButtonProps,
  backButton?: PageContentButtonProps,
  prevButton?: PageContentButtonProps,
};

export const PageContent = ({
  nextButton,
  backButton,
  prevButton,
  children,
  title,
  subtitle,
  currentProgress,
  showButtonBar = true,
}: PageContentProps) => {

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const license = useAppSelector((state) => state.user.license);

  useEffect(() => {
    if(currentUser === null) {
      dispatch(fetchCurrentUser());
    }
    if(currentUser !== null && license === "NaN") {
      dispatch(fetchLicense());
      dispatch(fetchModelConfigs());
    }
  }, [currentUser, license]);

  return (
    <Box sx={PageContainerBoxStyle}>
      <Box  className="flex flex-col flex-1 overflow-hidden">
        <Box sx={{ fontSize: "30px" }} data-testid="title-wrapper">
          <Typography variant="h1" sx={{ fontSize: "32px" }} >
            {title}
          </Typography>
          {subtitle && (
            <Typography sx={{ paddingTop: "10px" }}>
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
          {children}
        </Box>
      </Box>

      {showButtonBar && (
        <Box sx={ButtonsBarBoxStyle} className="w-full flex flex-row justify-between h-[40px] gap-4 items-center">
          <Box>
            {backButton?.handler && (
              <Button
                disabled={backButton?.disabled}
                sx={{ width: "240px" }}
                variant="outlined"
                data-testid="back-button"
                onClick={backButton?.handler}
              >
                {backButton?.text ?? 'Go Back'}
              </Button>
            )}
          </Box>
          <Box className="flex flex-row items-center gap-8">

            {prevButton?.handler && (
              <Button
                variant="contained"
                sx={{ width: "240px" }}
                disabled={prevButton?.disabled}
                onClick={prevButton?.handler}
                data-testid="next-button"
              >
                <Box className="flex items-center justify-center" sx={{ flex: 2 }}>
                  <ChevronLeft />
                  {prevButton?.text ?? "Previous"}
                  {prevButton?.loading && <CircularProgress className={"!text-[#99A8A8A8]"} size={16} />}
                </Box>
              </Button>
            )}

            {currentProgress && (
              <Typography variant="body2" sx={{ color: "#99A8A8A8" }}>
                {currentProgress}
              </Typography>
            )}

            {nextButton?.handler && (
              <Button
                variant="contained"
                sx={{ width: "240px" }}
                disabled={nextButton?.disabled}
                onClick={nextButton?.handler}
                data-testid="next-button"
              >
                <Box className="flex items-center justify-center" sx={{ flex: 2 }}>
                  {nextButton?.text ?? "Next"}
                  <ChevronRight />
                  {nextButton?.loading && <CircularProgress className={"!text-[#99A8A8A8]"} size={16} />}
                </Box>
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};