import { fetchAuthSession } from "@aws-amplify/auth";
import AWS from "aws-sdk";
interface LoginsObject {
  [key: string]: string;
}


export const getIdToken = async () => {
  const currentToken = await fetchAuthSession();
  return currentToken.tokens?.idToken?.toString();
};

export const configureCredentials = async () => {
  const idToken = await getIdToken();
  const idp = `cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`;
  const loginsObject: LoginsObject = {};
  loginsObject[idp] = idToken ?? "";

  const credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID ?? "",
    Logins: loginsObject,
  });

  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    credentials: credentials as AWS.Credentials,
  });

  await new Promise<void>((resolve, reject) => {
    credentials.refresh(err => {
      if (err) {
        console.error("Error refreshing credentials:", err);
        reject(err);
      } else {
        console.log("AWS credentials configured successfully.");
        resolve();
      }
    });
  });
};

export const getAWSConfig = async () => {
  if (
    !AWS.config.credentials || (AWS.config.credentials as AWS.Credentials).expired || needsRefresh(AWS.config.credentials as AWS.Credentials)
  ) {
    await configureCredentials();
  }
  return AWS.config;
};

export const isUserAuthenticated = async () => {
  try {
    const session = await fetchAuthSession();

    if (session.tokens) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error fetching auth session", error);
  }
};

export const clearSdkCredentials = () => {
  if (AWS.config.credentials) {
    (AWS.config.credentials as AWS.CognitoIdentityCredentials).clearCachedId();
  }
};


function needsRefresh(credentials: AWS.Credentials) {
  if (!credentials) return true;
  if (!credentials.expireTime) return true;
  const now = Date.now();
  const timeToExpiry = credentials.expireTime.getTime() - now; 
  return timeToExpiry < 60_000;
}