import { CustomCellRendererProps } from "ag-grid-react";
import { AnomalyResultsTagComponent } from "../../AnomalyTag/AnomalyTag";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DesignItProgress } from "@design-it/react-library";

export const iconStyle = () => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    cursor: "pointer",
  };
};

export const anomalyCellRenderer = (props: CustomCellRendererProps) => {
  if (props.value !== undefined) {
    return <AnomalyResultsTagComponent value={props.value} />;
  }
};

export const arrowCellRenderer = 
  (props: CustomCellRendererProps, func: (arg: unknown) => void) => (
    <ArrowForwardIcon
      style={{ cursor: "pointer" }}
      onClick={() => func(props.data)}
    />
  );

export const idCellRenderer = (props: CustomCellRendererProps) => {
  return props.value ? (
    `${props.data.processId}`
  ) : (
    <DesignItProgress
      indeterminate
      style={{ "--md-circular-progress-size": "30px" }}
    />
  );
};