import "./BoltingDataDetail.scss";

export interface DetailPanelRecommendationProps {
  recommendation: { [key: string]: [{[key: string]: string}] };
}

const recommendationContentRenderer = (recommendation: { [key: string]: [{ [key: string]: string }] }) => {
  return (
    <div className="detail-view-recommendation-tile-content">
      {recommendation.detail.map((e, index) => (
        <div key={e.header} className="detail-view-recommendation-item">
          <span className="detail-view-recommendation-key">&nbsp;{index + 1}. {e.header}</span>
          <span className="detail-view-recommendation-value">{e.description}</span>
          <div>
            <hr/>
          </div>
        </div>
      ))}
    </div>
  );
};

export const DetailPanelRecommendation = ({recommendation}: DetailPanelRecommendationProps) => {
  return (
    <div className="detail-view-recommendation-tile">
      <div className="detail-view-recommendation-tile-scroller">
        <span>Recommendation</span>
        {recommendationContentRenderer(recommendation)}
      </div>
    </div>
  );
};