import React, { useEffect } from "react";
import { BoltingData } from "../../apis/types";
import { FeedbackValues } from "../../components/table/CustomHeader";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setFeedback, setFeedbackClassification } from "../../store/labellingSlice";

interface feedbackComponentInput {
  row: BoltingData;
  setClassifying: (classifying: boolean) => void;
}

export function FeedbackComponent(props: feedbackComponentInput) {
  

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setFeedback(props.row?.feedback || null));
    dispatch(setFeedbackClassification(props.row?.classification || null));
    if (props.row?.feedback === FeedbackValues.NOK) {
      props.setClassifying(true);
    }
  }, [dispatch, props.row?.feedback]);

  const currentFeedback = useAppSelector((state) => state.labelling.feedback);

  const setNewFeedback = (feedback: string) => {
    if(feedback === FeedbackValues.OK) {
      dispatch(setFeedbackClassification(null));
      props.setClassifying(false);
      dispatch(setFeedback(feedback));

    }
    if (feedback === FeedbackValues.NOK) {
      props.setClassifying(true);
      dispatch(setFeedback(feedback));

    }

    if (feedback === null) {
      dispatch(setFeedbackClassification(null));
      dispatch(setFeedback(FeedbackValues.NONE));
      
      props.setClassifying(false);
    }
  };

  return (
    <Box className="w-full">
      <ToggleButtonGroup
        value={currentFeedback}
        exclusive
        onChange={(_, v) => setNewFeedback(v)}
        className="w-full border !rounded-xl box-border"
      >
        <ToggleButton
          size="small"
          sx={{ textTransform: "none", backgroundColor: currentFeedback === FeedbackValues.NOK ? "#ba1a1a!important" : "inherit" }}
          value={FeedbackValues.NOK}
          className={"!rounded-l-xl w-[50%] box-border " + (currentFeedback === FeedbackValues.NOK ? "!bg-error" : "text-inherit") }
        >
          Anomaly
        </ToggleButton>
        <ToggleButton
          size="small"
          sx={{ textTransform: "none"}}
          value={FeedbackValues.OK}
          className={"!rounded-r-xl w-[52%] box-border " + (currentFeedback === FeedbackValues.OK ? "!bg-primary !text-sys_on_primary" : "text-inherit")}
        >
          No Anomaly
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
