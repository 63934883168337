/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { BoltingData, DbBoltingData } from "../../apis/types";
import { ICellRendererParams } from "ag-grid-community";
import { DesignItProgress, DesignItTag } from "@design-it/react-library";
import { CustomCellRendererProps } from "ag-grid-react";
import { format } from "date-fns";
import { ModelConfig } from "../../pages/AnalysisPage/types";
import defaultRecommendations from "../../assets/recommendations.json";

export enum FeedbackValues {
  NONE = "NONE",
  OK = "OK",
  NOK = "NOK",
}

type CustomHeaderProps = {
  icon?: React.ReactElement;
  title: string;
};

const CustomHeader = (props: CustomHeaderProps) => {
  return (
    <div className="custom-header">
      <span className="header-icon">{props.icon} </span>
      <span className="header-title">{props.title}</span>
    </div>
  );
};

type CustomResultHeaderProps = {
  title: string;
  icon?: React.ReactElement;
};
const CustomResultsHeader = (props: CustomResultHeaderProps) => {
  return (
    <div
      className="custom-header"
      style={{
        display: "flex",
        justifyContent: "space-between", // Distribute space between title and icon
        alignItems: "left",
        width: "100%", // Ensure it takes up full width
      }}
    >
      <span className="header-title">{props.title}</span>
      <span className="header-icon">{props.icon} </span>
    </div>
  );
};

export const CustomResultsHeaderBuilder = (
  title: string,
  icon?: React.ReactElement
) => {
  return function CustomResultHeaderComponent(props: unknown) {
    return (
      <CustomResultsHeader
        {...(props as CustomResultHeaderProps)}
        title={title}
        icon={icon}
      />
    );
  };
};

export type ResultsTableProps = {
  onArrowClick?: (row: DbBoltingData) => void;
  data?: BoltingData[];
  isLoading: boolean;
  className?: string;
  hideArrow?: boolean;
  rowsPerPage?: number;
  startPage?: number;
  nonMlFlow?: boolean;
  modelConfig?: ModelConfig;
};

export type RowViewTableProps = {
  onArrowClick?: (row: DbBoltingData) => void;
  data?: BoltingData[];
  isLoading: boolean;
  className?: string;
  hideArrow?: boolean;
  modelExists: boolean;
  nonMlFlow: boolean;
};

export type LabellingTableProps = {
  className?: string;
  onArrowClick?: (row: DbBoltingData) => void;
  setUnlabelledDataFound: (unlabelledDataFound: boolean) => void;
  setLoadedAllData: (loadedAllData: boolean) => void;
};

export const CustomHeaderBuilder = (
  title: string,
  icon: React.ReactElement
) => {
  return function CustomHeaderComponent(props: unknown) {
    return (
      <CustomHeader
        {...(props as CustomHeaderProps)}
        title={title}
        icon={icon}
      />
    );
  };
};

export const useIdCellRenderer = () => {
  return useCallback((props: ICellRendererParams) => {
    if (props.value !== undefined) {
      return `${props.data.productId}`;
    } else {
      return (
        <DesignItProgress
          style={{ "--md-circular-progress-size": "30px" }}
          indeterminate
        />
      );
    }
  }, []);
};

const ProcessColumn = () => ({
  field: "processId",
  headerComponent: CustomResultsHeaderBuilder("Process ID"),
  flex: 4,
  cellClass: "custom-cell",
  cellRenderer: (props: CustomCellRendererProps) => {
    if (props.value !== undefined) {
      return `${props.data.processId}`;
    }
  },
});

const ProductColumn = () => (    {
  field: "productId",
  headerComponent: CustomResultsHeaderBuilder("Product ID"),
  flex: 3,
  cellClass: "custom-cell",
  valueGetter: "node.id",
  cellRenderer: (props: CustomCellRendererProps) => {
    if (props.value !== undefined) {
      return `${props.data.productId}`;
    }
  },
});

const TimestampColumn = () => ({
  field: "createdDate",
  valueGetter: "node.id",
  headerComponent: CustomResultsHeaderBuilder("Timestamp"),
  cellRenderer: (props: CustomCellRendererProps) => {
    if (props.value !== undefined) {
      const date = new Date(props.data.createdDate); // Ensure correct date parsing
      if (!isNaN(date.getTime())) {
        return format(date, "dd.MM.yyyy, hh:mm:ss");
      } else {
        return "Invalid date";
      }
    }
  },
  flex: 3,
  cellClass: "custom-cell",
});

const feedbackColumn = (onArrowClick?: (row: DbBoltingData) => void) => ({
  field: "feedback",
  headerComponent: () => "Feedback",
  flex: 3,
  cellRenderer: (props: CustomCellRendererProps) => {
    if (props.value !== undefined) {
      return (
        <DesignItTag
          onClick={() => onArrowClick?.(props.data)}
          key={props.value}
          className="ics-green-light"
          variant={(() => {
            if (props.value === FeedbackValues.NOK) {
              return "error";
            }
            if (props.value === FeedbackValues.OK) {
              return "success";
            }
            return "surface";
          })()}
          icon-name={(() => {
            if (props.value === FeedbackValues.NOK) {
              return "do_not_disturb_on";
            }
            if (props.value === FeedbackValues.OK) {
              return "check_circle";
            }
            return "info";
          })()}
          icon-position="first"
        >
          {(() => {
            if (props.value === FeedbackValues.NOK) {
              return "anomaly";
            }
            if (props.value === FeedbackValues.OK) {
              return "no anomaly";
            }
            return "wanna check this";
          })()}
        </DesignItTag>
      );
    }
  },
  cellClass: "custom-cell",
});

const anomalyDetectedColumn = (name: string) => ({
  field: "anomalyDetected",
  headerComponent: () => name,
  flex: 3,
  cellRenderer: (props: CustomCellRendererProps) => {
    if (props.value === "yes") {
      return (
        <DesignItTag
          className="ics-green-light"
          variant="error"
          icon-name="do_not_disturb_on"
          icon-position="first"
        >
          anomaly
        </DesignItTag>
      );
    }

    if (props.value === "no") {
      return (
        <DesignItTag
          className="ics-green-light"
          variant="success"
          icon-name="check_circle"
          icon-position="first"
        >
          no anomaly
        </DesignItTag>
      );
    }
  },
  cellClass: "custom-cell",
});

const classificationColumn = () => (    {
  field: "classification",
  headerComponent: () => "Classification",
  cellStyle: { justifyContent: "start" },
  cellRenderer: (props: CustomCellRendererProps) => {
    if(props.data.feedback === FeedbackValues.OK) {
      return "N/A";
    } else if (props.value && props.value !== "") {
      return props.value;
    } else {
      return '-';
    }
  },
  flex: 3,
  cellClass: "custom-cell",
});

const toolResultsColumn = (name: string) => (    {
  field: "status",
  headerComponent: () => name,
  flex: 2,
  cellClass: "custom-cell",
  cellStyle: { justifyContent: "start" },
});

export const getLabellingTableColDefs = (
  idCellRenderer: any,
  onArrowClick: ((row: DbBoltingData) => void) | undefined
) => {
  const colDefs: any = [
    /**
     * Since something's off with the AgGrid column definitions, we're using any[] here.
     * Need to be refactored to use ColDef[] or ColGroupDef[].
     */
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    ProcessColumn(),
    ProductColumn(),
    TimestampColumn(),
    toolResultsColumn("Tool Results"),
    feedbackColumn(onArrowClick),
    anomalyDetectedColumn("AI Prediction"),
    classificationColumn(),
  ];

  return colDefs;
};

export const getLabellingDetailsTableColDefs = (
  idCellRenderer: any,
  onArrowClick: ((row: DbBoltingData) => void) | undefined
) => {
  const colDefs: any = [
    /**
     * Since something's off with the AgGrid column definitions, we're using any[] here.
     * Need to be refactored to use ColDef[] or ColGroupDef[].
     */
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    ProcessColumn(),
    ProductColumn(),
    toolResultsColumn("Tool Result"),
    TimestampColumn(),
    feedbackColumn(onArrowClick),
    anomalyDetectedColumn("AI Prediction"),
    classificationColumn(),
  ];

  return colDefs;
};

export const getResultTableColDefs = (
  idCellRenderer: any,
  anomalyCellRenderer: any,
  arrowCellRenderer: any,
  hideArrow: boolean,
  nonMlFlow: boolean,
  navigationFunc?: any,
  modelConfig?: ModelConfig
) => {
  const colDefs: any[] = [
    ProcessColumn(),
    ProductColumn(),
    TimestampColumn(),
    toolResultsColumn("Tool Result"),
    anomalyDetectedColumn("AI Result"),
    {
      field: "classification_prediction",
      headerComponent: CustomResultsHeaderBuilder("AI Classification"),
      flex: 3,
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined && props.value !== '') {
          return `${props.data.classification_prediction}`;
        }
        else {
          return "-";
        }
      },
    },
    {
      field: "classification_prediction",
      headerComponent: CustomResultsHeaderBuilder("AI Recommendation"),
      flex: 7,
      wrapText: true,
      autoHeight: true, 
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined && modelConfig) {
          if (modelConfig?.recommendation) {
            return modelConfig?.recommendation && `${modelConfig.recommendation[props.data.classification_prediction as keyof typeof modelConfig.recommendation]['header']}`;
          } else if (Object.hasOwn(defaultRecommendations, props.data.classification_prediction)) {
            return defaultRecommendations[props.data.classification_prediction as keyof typeof defaultRecommendations]['header'];
          } else {
            return "-";
          }
        }
      },
    }
  ];

  if (!hideArrow) {
    colDefs.push({
      headerComponent: CustomResultsHeaderBuilder("Action"),
      cellRenderer: (props: any) => arrowCellRenderer(props, navigationFunc),
      flex: 2,
      cellClass: "custom-cell",
    });
  }

  return nonMlFlow
    ? colDefs.filter(col => col.field !== "classification_prediction")
    : colDefs;
};