import "./BoltingDataDetail.scss";
import { BoltingData } from "../../apis/types";
import { useState } from "react";
import { FeedbackComponent } from "../dashboard/feedbackComponent";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { primaryColors } from "../../theme/DarkTheme";
import { useResultsContext } from "../../providers/resultsProvider";
import {useAppSelector } from "../../store/hooks";
import ClassificationFeedbackComponent from "../dashboard/classificationFeedbackComponent";

export interface DetailPanelFeedbackProps {
    rowToUse: BoltingData;
}

const TOOLTIP_TEXT = "To train the AI model, we need feedback from you, whether you can see an anomaly on this curve or not.";

export const DetailPanelFeedback = ({rowToUse}: DetailPanelFeedbackProps ) => {
  const [classifying, setClassifying] = useState(false);
  const license = useAppSelector((state) => state.user.license);
  const { setCurrentRow } = useResultsContext();
  
  return (
    <>
      <div className={"detail-view-recommendation-tile" + (classifying ? " !grow-0" : "") } >
        <div className="detail-view-recommendation-tile-scroller">
          <div className="flex justify-between items-center">
            <span className="text-xl">Your Feedback</span>
            <Tooltip title={<Box sx={{ fontSize: '12px' }}>{TOOLTIP_TEXT}</Box>}>
              <InfoOutlinedIcon sx={{ color: primaryColors.main }}  fontSize={"medium"} />
            </Tooltip>
          </div>
          <div className="detail-view-recommendation-tile-content">
            <FeedbackComponent row={rowToUse} setClassifying={setClassifying} />
          </div>
        </div>
      </div>
      {classifying && license === "premium" && (
        <div className="detail-view-recommendation-tile">
          
          <div className="detail-view-recommendation-tile-scroller">
            <div className="flex justify-between items-center">
              <span className="text-xl">Classify the anomaly</span>
            </div>
            <div className="detail-view-recommendation-tile-content">
              <ClassificationFeedbackComponent
                row={rowToUse}
                updateSelectedRow={setCurrentRow}
              />            </div>
          </div>
        </div>
      )}
    </>
  );
};