import "./BoltingDataDetail.scss";
import { BoltingData } from "../../apis/types";
import { DetailPanelTile } from "./DetailPanelTile";
import { useAppSelector } from "../../store/hooks";
import { DetailPremiumComponent } from "./DetailPremiumComponent";
import { format } from "date-fns";
import { ModelConfig } from "../AnalysisPage/types";

interface DetailPanelProps {
  data: BoltingData;
  modelConfig?: ModelConfig;
  flow : "labelling" | "results";
}

export const DetailPanel = ({ data, modelConfig, flow }: DetailPanelProps) => {
  const license = useAppSelector((state) => state.user.license);
  const timestamp = format(
    new Date(data.createdDate),
    "dd.MM.yyyy hh:mm:ss"
  );
  return (
    <div className="detail-view-panel">
      <DetailPanelTile label="Process ID" value={data.processId} />
      <DetailPanelTile label="Product ID" value={data.productId} />
      <DetailPanelTile label="Tool result" value={data.status} />
      <DetailPanelTile label="Time stamp" value={timestamp} />
      <DetailPanelTile label="AI Prediction" value={data.anomalyDetected} />
      {license === "premium" && (
        <DetailPremiumComponent data={data} modelConfig={modelConfig} flow={flow} />
      )}
    </div>
  );
};
