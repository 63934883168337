
import { createTheme } from "@mui/material/styles";

export const primaryColors = {
  main: "#8EDA81",
  light: "#8EDA80",
  dark: "#83d548",
  contrastText: "#FFFFFF"
}; // green pallete

export const secondaryColors = {
  main: "#00002C",
  light: "#000039",
  contrastText: "#000000"
}; // blue pallete

export const tertiaryColors = {
  dark:  "#262640", // disable 
  main: "#ffffff", // white
  light: "#E0E0E0", // tab-text
  contrastText: "#99A8A8A8" // outlined border 
}; // grey pallete

export const transparent = {
  main: "transparent",
  light: "#0D0D3b", // hover - opacity
  contrastText: "#99A8A8A8"
};

export const errorColors = {
  main: "#FF0000", // red error - anomaly
  light: "#ffb4ab" // info flag 
};

export const successColors = {
  main: "#06BA1D" // green 
};

export const warningColors = {
  dark: "#262640",  // disable 
  main: "#FFCC01",  // yellow warning
  light: "#0D0D3b", // opacity -- hover
};

export const theme = createTheme({
  typography: {
    fontFamily: 'var(--md-sys-typescale-label-medium-font)',
    fontSize: 12,
    fontWeightBold: "0px",
    body1: {
      root: {
        color: "#ffffff"
      }
    },
    body2: {
      root: {
        color: "#83D461"
      }
    },
    subtitle2: {
      root: {
        color: "#000000"
      }
    }
  },

  palette: {
    mode: "dark",
    primary: primaryColors,
    secondary: secondaryColors,
    error: errorColors,
    success: successColors,
    info: tertiaryColors,
    warning: warningColors
  },

  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: transparent.light,
          borderTopLeftRadius: "10px",
        },
        indicator: {
          backgroundColor: primaryColors.main,
          height: '1px',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'var(--md-sys-typescale-label-large-font)',
          fontSize: 'var(--md-sys-typescale-label-large-size)',
          color: tertiaryColors.main,
          "&.Mui-disabled": {
            color: tertiaryColors.contrastText
          },
          '&.Mui-selected': {
            backgroundColor: tertiaryColors.dark,
            color: tertiaryColors.main,
          },
        }
      }
    },

    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          display: "flex",
          gap: 8,
          fontSize: '12px',
          width: "100%",
          padding: "8px 32px",
          borderRadius: "8px",
          fontFamily: '"frutiger", Arial, sans-serif',
          textTransform: 'none',
        },
        contained: {
          textTransform: 'none',
          color: secondaryColors.contrastText,
          backgroundColor: primaryColors.main,
          "&:hover": {
            backgroundColor: primaryColors.dark,
          },
          ":disabled": {
            backgroundColor: tertiaryColors.dark,
            color: tertiaryColors.contrastText,
          }
        },
        outlined: {
          color: primaryColors.main,
          border: `0.1px solid ${tertiaryColors.contrastText}`,
          backgroundColor: transparent.main,
          "&:hover": {
            backgroundColor: tertiaryColors.contrastText,
            border: `0.1px solid ${transparent.light}`,
          },
          ":disabled": {
            backgroundColor: tertiaryColors.dark,
            color: tertiaryColors.contrastText,
          }
        },
        text: {
          backgroundColor: transparent.main,
          color: primaryColors.main,
          border: transparent.main,
          "&:hover": {
            backgroundColor: transparent.main,
          },
          ":disabled": {
            backgroundColor: tertiaryColors.dark,
            color: tertiaryColors.contrastText,
          },
          '&:active': {
            backgroundColor: 'transparent !important'
          }
        },
      }
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          minHeight: '74px',
          backgroundColor: transparent.main,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          color: tertiaryColors.main,
          top: "14px",
          padding: '0 4px',
          "&.Mui-focused": {
            backgroundColor: transparent.light,
            border: transparent.main,
            color: primaryColors.main,
            top: '17px',
            borderBottom: "none",
          },
          '&.MuiFormLabel-filled': {
            top: '17px',
            backgroundColor: transparent.light,
          },
          "&.Mui-disabled": {
            color: tertiaryColors.main,
          },
          "&.Mui-error": {
            color: errorColors.main
          }
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          color: tertiaryColors.main,
          border: `1px solid ${tertiaryColors.main}`,
          "&:before": {
            border: "none",
          },
          borderRadius: "5px",
          "&:hover:not(.Mui-disabled):after": {
            borderBottom: transparent.main,
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: transparent.main,
          },
          "&.Mui-focused": {
            border: `1px solid ${primaryColors.main}`,
          },
          "&.Mui-focused:after": {
            borderBottom: transparent.main,
          },
          "&.Mui-filled": {
            border: `1px solid ${primaryColors.main}`,
          },
          "&.Mui-disabled": {
            backgroundColor: tertiaryColors.dark,
          },
          "&.Mui-disabled:before": {
            border: transparent.main
          },
          '&.Mui-error': {
            border: `1px solid ${errorColors.main}`,
          }
        },
        input: {
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingLeft: "14px",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: `1px solid ${tertiaryColors.main}`,
          marginTop: "15px",
          marginLeft: "2px",
          borderRadius: "5px",
          "& .MuiOutlinedInput-root": {
            padding: "10px"
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: transparent.main
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: transparent.main
          },
          "& .MuiAutocomplete-input": {
            color: tertiaryColors.main,
          }
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiInput-root": {
            color: tertiaryColors.main
          },
          "& input": {
            borderRadius: "5px",
            paddingTop: "15px !important",
            paddingBottom: "15px !important",
            paddingLeft: "14px !important",
          },
          "& .Mui-focused": {
            border: `1px solid ${primaryColors.main}`,
            padding: " 0px 4px"
          },
          "& .Mui-disabled": {
            backgroundColor: tertiaryColors.dark,
            borderRadius: "5px",
          },
        },
        noOptions: {
          height: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        paper: {
          backgroundColor: secondaryColors.main,
          color: tertiaryColors.main,
        },
        option: {
          height: "70px",
          display: "flex",
          alignItems: "center",
          color: tertiaryColors.main,
          "&:hover": {
            backgroundColor: tertiaryColors.dark,
            color: tertiaryColors.main,
          }
        },
        clearIndicator: {
          color: tertiaryColors.main,
          "&:hover": {
            color: tertiaryColors.main,
            backgroundColor: tertiaryColors.dark,
          },
        },
        popupIndicator: {
          color: tertiaryColors.main,
          "&:hover": {
            color: tertiaryColors.main,
            backgroundColor: tertiaryColors.dark,
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: secondaryColors.main,
        },
      },
    },

    MuiTypography : {
      variants:[
        {
          props: { variant: 'h1' },
          style:{
            fontFamily: 'var(--md-sys-typescale-headline-large-font)',
          }
        }
      ]
    }
  },
});
