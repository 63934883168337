import NormalizedGraph from "../../components/normalizedGraph/graph";
import { DesignItCard, DesignItProgress } from "@design-it/react-library";
import { getCurrentUser } from "@aws-amplify/auth";
import moment from "moment";
import { apiHelper } from "../../apis/apiHelper";
import { updateUploadCount } from "../../utils/uploadLimitHelpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Navigate } from "react-router";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect, useState, useRef, useMemo } from "react";
import { useCSVContext } from "../../providers/CSVProvider";
import {
  downloadFromPresignedUrl,
  fetchPresignedUrl,
} from "../upload/uploadHelpers";
import { columnMapper } from "../dashboard/uploadHelper";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
  NonMLFlowStepperLabel,
} from "../../constants/stepper.constants";
import { DataCheckModal } from "../../components/DataCheckModal/DataCheckModal";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Divider,
  InputAdornment,
} from "@mui/material";
import { Stepper } from "../dashboard/Stepper";
import { PageContent } from "../../components/PageContent/PageContent";
import { BoltingAttributes } from "../dashboard/fileUploadComponent";
import { clearLocalStorage } from "../upload/uploadHelpers";
import { isCorrectBox } from "../../utils/graphUtils";

const UnloadedGraphBoxStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const ThresholdInput = ({name, label, value, unit, handleChange, handleBlur }: { 
    name: string, 
    label: string; 
    value: string, 
    unit: string, 
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  }) => {

  return (
    <Box className="w-full">
      <TextField
        name={name}
        label={label}
        variant="outlined"
        value={value}
        type="text"
        inputMode="numeric"
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: <InputAdornment position="start" style={{
            position: "absolute",
            left: `${value.length + 1.9}ch`,
            width: "0",
          }}>{unit}</InputAdornment>,
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            padding: "0",

            "&.Mui-focused": {
              borderColor: "var(--mui-focus-ring,#8EDA81)",
            },
          },
          "& .MuiInputLabel-root": {
            backgroundColor: "#282841",
            padding: "0 4px",
          },
          "& .Mui-focused .MuiInputLabel-root": {
            color: "#282841",
          },

          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#8EDA81",
          },
        }}
        className="w-full"
      />
    </Box>
  );
};

const handleProductIdChange = (event: React.SyntheticEvent, newValue: string | null) => {
  const iframe = document.getElementById("datashader-graph") as HTMLIFrameElement;
  iframe?.contentWindow?.postMessage({ 
    type: "highlightSelectedProductId", 
    productId: newValue
  },
  `${process.env.REACT_APP_GRAPH_API_ROOT_URL}`);
};

type DataCheckPageProps = {
  nonMlFlow: boolean;
};

const getProductIds = (lines: BoltingAttributes[]) => {
  return Array.from(new Set(lines.map(line => line.id)));
};

export const DataCheckPage = ({ nonMlFlow }: DataCheckPageProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fileName = searchParams.get("fileName");
  const lastStep = searchParams.get("lastStep");
  const configName = searchParams.get("configName");

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [jobId, setJobId] = useState<string>("");

  const { isExecuteTabAnalysis } = useStepperContext();

  const { lines, setLines } = useCSVContext();

  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const productIds = useMemo(() => getProductIds(lines), [lines]);

  const [thresholds, setThresholds] = useState({
    xMin: "",
    xMax: "",
    yMin: "",
    yMax: "",
  });

  const uploadBucketName = process.env.REACT_APP_UPLOAD_BUCKET_NAME ?? "raw-upload-bucket";
  const graphURL = `${process.env.REACT_APP_GRAPH_API_ROOT_URL}?filename=${fileName}&bucketname=${uploadBucketName}&dir=raw`;

  const updateGraphSelection = (thresholdValues: { xMin: string; xMax: string; yMin: string; yMax: string; }) => {
    const iframe = document.getElementById("datashader-graph") as HTMLIFrameElement;

    const x0 = parseFloat(thresholdValues.xMin);
    const x1 = parseFloat(thresholdValues.xMax);
    const y0 = parseFloat(thresholdValues.yMin);
    const y1 = parseFloat(thresholdValues.yMax);
    
    if (!isCorrectBox(x0, x1, y0, y1)) {
      return;
    }

    iframe?.contentWindow?.postMessage({
      type: "updateSelectionBox", 
      selections: { xMin: x0, xMax: x1, yMin: y0, yMax: y1 }
    },
    `${process.env.REACT_APP_GRAPH_API_ROOT_URL}`);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    event.target.style.paddingRight = "6px";

    // Get the value and remove any non-numeric characters
    let newValue = value.replace(/[^0-9.,-]/g, "");
    // Remove any extra decimal points
    newValue = newValue.replace(/(?<=\..*)\./g, "");
    newValue = newValue.replace(/(?<=,.*)\./g, "");
    // Remove any extra commas
    newValue = newValue.replace(/(?<=,.*),/g, "");
    newValue = newValue.replace(/(?<=\..*),/g, "");
    // Remove any extra negative signs
    newValue = newValue.replace(/(?<!^)-/g, "");

    setThresholds((prevValues) => ({ ...prevValues, [name]: newValue }));
    updateGraphSelection({ ...thresholds, [name]: value });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const newValue = parseFloat(value);
    if (isNaN(newValue)) {
      setThresholds((prevValues) =>
        ({ ...prevValues, [name]: "" }));
      return;
    }

    setThresholds((prevValues) => 
      ({ ...prevValues, [name]: newValue.toFixed(2) }));
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === containerRef.current) {
          setContainerWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    async function loadData() {
      if (!lines.length) {
        const preSignedUrl = await fetchPresignedUrl(fileName as string);
        const jsonData = await downloadFromPresignedUrl(preSignedUrl);
        const fileLines = jsonData.map(columnMapper);
        setLines(fileLines);
      }
    }
    loadData();
  }, []);

  useEffect(() => {
    const messageHandler = async (event: MessageEvent) => {
      if (event.data && event.data.type === "sessionData") {
        const sessionCookie = JSON.parse(event.data.cookie)["session-cookie"];
        const urlToFetch = process.env.REACT_APP_GRAPH_API_ROOT_URL + "/get-selected-ids";
        const selectedIds = (await fetch(
          urlToFetch, {
            method: "GET",
            headers: {
              'Content-Type': 'application/json',
              "Cookie": `session=${sessionCookie}`,
            },
            credentials: 'include',
          }
        ).then(response => response.json()))["selected_ids"];

        if (selectedIds && selectedIds.length > 0) {
          const jobId = moment().format("YYYY-MM-DD_HH:mm:ss.SSS");
          setJobId(jobId);
          localStorage.setItem("jobId", jobId);
          const { userId } = await getCurrentUser();

          apiHelper.post(
            process.env.REACT_APP_REST_API_ROOT_URL + "/start-workflow",
            {
              "user-id": userId,
              "bucket-name": uploadBucketName,
              "file-key": `raw/${fileName}`,
              "job-id": jobId,
              "config-name": `${configName}`,
              "flow-type": nonMlFlow ? "non-ml" : "ml",
              "selected-ids": selectedIds,
            }
          );

          clearLocalStorage();

          updateUploadCount(1, userId);
          handleOpen();
        } else {
          alert("Please apply a valid selection before continuing.");
        }
      } else if (event.data && event.data.type === "selectionBoxServerUpdate") {
        const xMin = event.data.selections.xMin === undefined ? "" : event.data.selections.xMin.toFixed(2);
        const xMax = event.data.selections.xMax === undefined ? "" : event.data.selections.xMax.toFixed(2);
        const yMin = event.data.selections.yMin === undefined ? "" : event.data.selections.yMin.toFixed(2);
        const yMax = event.data.selections.yMax === undefined ? "" : event.data.selections.yMax.toFixed(2);
        setThresholds({
          xMin: xMin,
          xMax: xMax,
          yMin: yMin,
          yMax: yMax,
        });
        for (const key in event.data.selections) {
          const input = document.querySelector(`input[name=${key}]`);
          input?.setAttribute('style', 'padding-right: 6px');
        }
      }
    };

    const handlerWrapper = (event: MessageEvent) => {
      messageHandler(event);
    };

    window.addEventListener("message", handlerWrapper);

    return () => {
      window.removeEventListener("message", handlerWrapper);
    };
  }, []);

  if (!fileName) {
    return <Navigate to={"/analysis"} />;
  }

  const onContinueClick = async () => {
    if (nonMlFlow) {
      const iframe = document.getElementById("datashader-graph") as HTMLIFrameElement;
      iframe?.contentWindow?.postMessage({ type: "getSessionCookies" }, `${process.env.REACT_APP_GRAPH_API_ROOT_URL}`);
      return;
    }

    if (lastStep === "results" && !nonMlFlow) {
      navigate(`/results?fileName=${fileName}&configName=${configName}`);
    } else if (lastStep === "labelling") {
      navigate(`/labelling?fileName=${fileName}&configName=${configName}`);
    } else {
      const jobId = moment().format("YYYY-MM-DD_HH:mm:ss.SSS");
      setJobId(jobId);
      localStorage.setItem("jobId", jobId);
      const { userId } = await getCurrentUser();
      apiHelper.post(
        process.env.REACT_APP_REST_API_ROOT_URL + "/start-workflow",
        {
          "user-id": userId,
          "bucket-name": uploadBucketName,
          "file-key": `raw/${fileName}`,
          "job-id": jobId,
          "config-name": `${configName}`,
          "flow-type": nonMlFlow ? "non-ml" : "ml",
          "selected-ids": [],
        }
      );
      updateUploadCount(1, userId);
      handleOpen();
    }
  };

  const tabName = isExecuteTabAnalysis ? "execute" : "prepare";
  const MLFlowStepperLabel = isExecuteTabAnalysis
    ? MLFlowStepperLabelsExecuteTab
    : MLFlowStepperLabelsAnalysisTab;
  const stepperLabel = nonMlFlow ? NonMLFlowStepperLabel : MLFlowStepperLabel;

  const backButtonLocation = nonMlFlow
    ? "/non-ml-flow-analysis"
    : `/analysis?activeTab=${tabName}`;

  const mlFlowPageTitle =
    "Validate the uploaded curves for " +
    (isExecuteTabAnalysis ? "analysis" : "training");
  const pageTitle = nonMlFlow ? "Localize the anomalies" : mlFlowPageTitle;

  return (
    <PageContent
      title={pageTitle}
      showButtonBar={true}
      backButton={{ handler: () => navigate(backButtonLocation) }}
      nextButton={{
        handler: onContinueClick,
        text: nonMlFlow ? "Generate results" : "Next",
      }}
    >
      <Box data-testid="stepper-wrapper">
        <Stepper
          steps={stepperLabel}
          currentStep={2}
          shouldShowStepper={true}
        />
      </Box>

      <Box
        sx={{ height: "100%" }}
        className="flex gap-[10px] justify-center items-center overflow-auto"
      >
        <Box className="h-full grow flex bg-surface overflow-hidden rounded-xl" ref={containerRef}>
          <DesignItCard
            state={undefined}
            className="bg-none grow"
          >
            {(() => {
              if (nonMlFlow) {
                return (
                  <iframe
                    id="datashader-graph"
                    src={graphURL}
                    title={"Non-ML Flow Graph"}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                );
              } else if (lines.length === 0) {
                return (
                  <Box sx={UnloadedGraphBoxStyle}>
                    <DesignItProgress indeterminate />
                    <Typography>Building Graph...</Typography>
                  </Box>
                );
              } else {
                return (
                  <NormalizedGraph
                    lines={lines}
                    containerWidth={containerWidth}
                  />
                );
              }
            })()}
          </DesignItCard>
        </Box>

        {nonMlFlow && (
          <Box
            className="h-full bg-surface w-fit flex flex-col gap-6  overflow-y-scroll overflow-x-hidden custom-scroll rounded-xl"
          >
            <Box
              className="w-80 py-4 px-6 h-full flex flex-col justify-start items-start overflow-hidden"
              sx={{
                fontStyle: "normal",
                fontWeight: "400",
                minHeight: "fit-content",
              }}
            >
              <Typography
                sx={{ fontSize: "22px" }}
                className="pb-3"
                variant="h1"
              >
                {" "}
                Your Feedback
              </Typography>
              <Typography
                sx={{ fontSize: "16px" }}
                className="pb-3"
                variant="h1"
              >
                To assist with determining anomalies, you can type in a known
                Product ID of your uploaded data set that contains an anomaly.
              </Typography>

              <Autocomplete
                options={productIds}
                onChange={handleProductIdChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={"Product ID"}
                    sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
                  />
                )}
                className="w-full"
              />
              <Divider
                sx={{
                  width: "292px",
                  height: "1px",
                  backgroundColor: "#E2E6EB",
                  my: 2,
                }}
              />
              <Typography
                sx={{ fontSize: "22px" }}
                className="pb-3"
                variant="h1"
              >
                Determine thresholds
              </Typography>
              <Box
                className="w-full flex-col gap-3"
              >
                <ThresholdInput 
                  name="xMin" 
                  label="Min. for Angle" 
                  value={thresholds.xMin} 
                  unit="°" 
                  handleChange={handleChange}
                  handleBlur={handleBlur} />
                <ThresholdInput 
                  name="xMax" 
                  label="Max. for Angle" 
                  value={thresholds.xMax} 
                  unit="°" 
                  handleChange={handleChange}
                  handleBlur={handleBlur} />
                <ThresholdInput 
                  name="yMin" 
                  label="Min. for Torque" 
                  value={thresholds.yMin}
                  unit="Nm"
                  handleChange={handleChange}
                  handleBlur={handleBlur} />
                <ThresholdInput 
                  name="yMax" 
                  label="Max. for Torque" 
                  value={thresholds.yMax}
                  unit="Nm"
                  handleChange={handleChange}
                  handleBlur={handleBlur} />
              </Box>
            </Box>{" "}
          </Box>
        )}
      </Box>

      {openModal && (
        <DataCheckModal
          open={openModal}
          handleClose={handleClose}
          jobId={jobId}
          fileName={fileName}
          configName={configName ?? "config1"}
          nonMlFlow={nonMlFlow}
        />
      )}
    </PageContent>
  );
};
