import { useNavigate, useSearchParams } from "react-router-dom";
import { ResultsTable } from "./ResultsTable";
import { useGetBoltingDataWithAnomalies } from "../../apis/useGetBoltingData";
import { useStepperContext } from "../../providers/StepperProvider";
import { DesignItProgress } from "@design-it/react-library";
import { downloadPdf } from "../../utils/reportHelpers";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
  NonMLFlowStepperLabel,
} from "../../constants/stepper.constants";
import { Stepper } from "../dashboard/Stepper";
import { PageContent } from "../../components/PageContent/PageContent";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { resetLabellingData } from "../../store/labellingSlice";
import { ModelConfig, ModelConfigs } from "../AnalysisPage/types";

type ResultsPageProps = {
  nonMlFlow: boolean;
};

export const ResultsPage = ({ nonMlFlow }: ResultsPageProps) => {
  const [fetchingPdf, setFetchingPdf] = useState(false);
  const { isExecuteTabAnalysis } = useStepperContext();
  const [modelConfig, setModelConfig] = useState<ModelConfig | undefined>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("fileName")!;
  const configName = searchParams.get("configName")!;
  const configLoadingStatus = useAppSelector(state => state.user.modelConfigLoadingStatus);
  const configData = useAppSelector(state => state.user.modelConfigs);
  const dispatch = useAppDispatch();



  const { data, isLoading } = useGetBoltingDataWithAnomalies();
  const startPage = 1;
  const rowsPerPage = 10;

  const MLFlowStepperLabel = isExecuteTabAnalysis
    ? MLFlowStepperLabelsExecuteTab
    : MLFlowStepperLabelsAnalysisTab;
  const stepperLabel = nonMlFlow ? NonMLFlowStepperLabel : MLFlowStepperLabel;

  const backButtonLocation = nonMlFlow
    ? `/non-ml-flow-data-check?fileName=${fileName}&configName=${configName}&lastStep=results`
    : `/data-check?fileName=${fileName}&configName=${configName}&lastStep=results`;
  const flowType = nonMlFlow ? "non-ml" : "ml";


  useEffect(() => {
    dispatch(resetLabellingData());
  }, []);

  useEffect(() => {
    (async () => {
      const data: ModelConfigs = configData as ModelConfigs;
      setModelConfig(data[configName]);
    })();
  }, [configLoadingStatus]);

  return (
    <PageContent
      title="Results"
      showButtonBar={true}
      nextButton={{
        text: "Download",
        disabled: fetchingPdf,
        handler: () =>
          downloadPdf(setFetchingPdf, configName, fileName, flowType),
        downloadVariant: true,
        loading: fetchingPdf,
      }}
      backButton={{ handler: () => navigate(backButtonLocation) }}
    >
      {isLoading && !data ? (
        <div className="w-full h-full flex justify-center items-center flex-col">
          <DesignItProgress indeterminate />
        </div>
      ) : (
        <>
          <Stepper
            steps={stepperLabel}
            currentStep={3}
            shouldShowStepper={true}
          />{" "}
          <ResultsTable
            nonMlFlow={nonMlFlow}
            isLoading={isLoading}
            onArrowClick={row =>
              nonMlFlow
                ? navigate(`/non-ml-flow-results/${row.id}?configName=${configName}`)
                : navigate(`/results/${row.id}?configName=${configName}`)
            }
            rowsPerPage={rowsPerPage}
            startPage={startPage}
            modelConfig={modelConfig}
          />
        </>
      )}
    </PageContent>
  );
};
