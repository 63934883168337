import { AuthUser, getCurrentUser } from "@aws-amplify/auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { getIdToken } from "../utils/credentialsHelper";
import { apiHelper, ApiResponse } from "../apis/apiHelper";
import { ModelConfigs } from "../pages/AnalysisPage/types";


export interface UserState {
  status: 'idle' | 'loading' | 'failed';
  currentUser: AuthUser | null;
  license: 'NaN' | 'basic' | 'premium' | 'enterprise';
  modelConfigs: ModelConfigs | object;
  modelConfigLoadingStatus: 'idle' | 'loading' | 'failed';
}

const initialState: UserState = {
  status: 'idle',
  currentUser: null,
  license: 'NaN',
  modelConfigs: {},
  modelConfigLoadingStatus: 'loading',
};

export const fetchCurrentUser = createAsyncThunk('user/fetchUser', async () => {
  console.log("Getting current user");
  const response = await getCurrentUser();
  return response;
});

export const fetchModelConfigs = createAsyncThunk('user/fetchModelConfig', async (_, thunkAPI) => {
  const user_id =  (thunkAPI.getState() as RootState).user.currentUser?.userId;

  const url =
    process.env.REACT_APP_REST_API_ROOT_URL +
    "/configurations/get-model-configs";

  const response: ApiResponse = await apiHelper.post(url, {
    user_id: user_id,
    operation: "fetch",
  });

  if (response && "model_configs" in response) {
    return response["model_configs"] as ModelConfigs;
  } else {
    throw new Error("Model configs not found in response");
  }
});

export const fetchLicense = createAsyncThunk('user/fetchLicense', async (_, thunkAPI) => {
  const currentUser = (thunkAPI.getState() as RootState).user.currentUser?.userId;
  const idToken = await getIdToken();
  const url = process.env.REACT_APP_REST_API_ROOT_URL +
    "/configurations/get-model-configs";
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      user_id: currentUser,
      operation: "check_license",
    }),
  });
  const result = await response.json();
  return result.license;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserInfo: (state) => {
      state.currentUser = null;
      state.license = 'NaN';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentUser = action.payload;
      })
      .addCase(fetchLicense.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLicense.fulfilled, (state, action) => {
        state.status = 'idle';
        state.license = action.payload;
      })
      .addCase(fetchModelConfigs.pending, (state) => {
        state.modelConfigLoadingStatus = 'loading';
      })
      .addCase(fetchModelConfigs.fulfilled, (state, action) => {
        state.modelConfigLoadingStatus = 'idle';
        state.modelConfigs = action.payload;
      });
  }
});

export default userSlice.reducer;

export const { resetUserInfo } = userSlice.actions;

export const selectCurrentUser = (state: { user: UserState }) => state.user.currentUser;