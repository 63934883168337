import "./BoltingDataDetail.scss";
import DetailedGraphView from "../../components/graphView/detailedGraphViewComponent";
// import { useResultsContext } from "../../providers/resultsProvider";
import { BoltingData } from "../../apis/types";
import { Box } from "@mui/material";
// import { RowViewTable } from "./rowViewTable";
// import { FeedbackComponent } from "../dashboard/feedbackComponent";
import { DetailPanel } from "./DetailPanel";
interface ResultViewComponentProps {
  rowToUse: BoltingData;
  nonMlFlow: boolean;
}

export const LabellingView = ({ rowToUse }: ResultViewComponentProps) => {
  // const { setCurrentRow } = useResultsContext();
  return (
    <div className="flex flex-col h-full gap-[32px]">
      <div className="result-detail-view-header">
        Feedback Required
      </div>
      <div className="result-detail-view-content flex h-full gap-[10px] max-h-[calc(100%-80px)]">
        <Box
          className="p-8 pt-0 grow min-w-0"
          sx={{ background: theme => theme.palette.info.dark, borderRadius: "12px", }}
        >
          {rowToUse && <DetailedGraphView row={rowToUse} />}
        </Box>
        <Box
        >
          <DetailPanel data={rowToUse} flow="labelling" />
        </Box>
      </div>
    </div>
  );
};
