import { Box } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./pages/login/index";
import { ResultsPage } from "./pages/results/ResultsPage";
import { BoltingDataDetailPage } from "./pages/bolting-data-detail/BoltingDataDetailPage";
import { LabellingPage } from "./pages/labelling/LabellingPage";
import { DataCheckPage } from "./pages/DataCheck/DataCheckPage";
import { AnalysisPage } from "./pages/AnalysisPage/AnalysisPage";
import { ProtectedRoute } from "./components/routeAuth/routeAuth";
import { StartPage } from "./pages/StartPage/StartPage";
import { defineCustomElements } from "@design-it/web-core/loader";
import { MenuBar } from "./components/MenuBar/MenuBar";
import { MainAppBar } from "./components/MainAppBar/MainAppBar";
import { secondaryColors, tertiaryColors } from "./theme/DarkTheme";
import "@fontsource/orbitron";
import { NonMLFlowAnalysis } from "./pages/NonMLFlowAnalysis/NonMLFlowAnalysis";

const PageContainerBoxStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  minHeight: "700px",
};

const ContentContainerBoxStyle = {
  bgcolor: tertiaryColors.dark,
  display: "flex",
  height: "calc(100% - 64px)",
};

const ContentContainerBoxStyleLogin = {
  bgColor: tertiaryColors.dark,
  display: "flex",
  height: "100vh",
};

const RoutesContainerBoxStyle = {
  flex: 1,
  bgcolor: secondaryColors.main,
  width: "calc(100% - 300px)",
};

const App: React.FC = () => {
  defineCustomElements();

  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  return (
    <Box sx={PageContainerBoxStyle}>
      {!isLoginPage && <MainAppBar />}

      <Box
        sx={
          isLoginPage ? ContentContainerBoxStyleLogin : ContentContainerBoxStyle
        }
      >
        {!isLoginPage && <MenuBar />}

        <Box sx={!isLoginPage ? RoutesContainerBoxStyle : {}}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/start" element={<StartPage />} />
              <Route path="/analysis" element={<AnalysisPage />} />
              <Route
                path="/data-check"
                element={<DataCheckPage nonMlFlow={false} />}
              />
              <Route path="/labelling" element={<LabellingPage />} />
              <Route
                path="/results"
                element={<ResultsPage nonMlFlow={false} />}
              />
              <Route path="/results/:id" element={<BoltingDataDetailPage />} />
              <Route
                path="/labelling/:id"
                element={<BoltingDataDetailPage modelExists={false} />}
              />
              <Route
                path="/non-ml-flow-analysis"
                element={<NonMLFlowAnalysis />}
              />
              <Route
                path="/non-ml-flow-data-check"
                element={<DataCheckPage nonMlFlow={true} />}
              />
              <Route
                path="/non-ml-flow-results"
                element={<ResultsPage nonMlFlow={true} />}
              />
              <Route
                path="/non-ml-flow-results/:id"
                element={<BoltingDataDetailPage nonMlFlow={true} />}
              />
            </Route>
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default App;
