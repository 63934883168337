import { Box, Button, LinearProgress, Modal, Typography } from "@mui/material";
import Lottie from 'react-lottie';
import animationData from "../../lotties/Brain-Master-1.json";
import { useGetModelAlreadyExists } from "../../apis/useGetModelAlreadyExists";
import { useGetPollProgress } from "../../apis/useGetPollProgress";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { queryClient } from "../../providers/ReactQueryProvider";
import "./style.scss";
import { tertiaryColors } from "../../theme/DarkTheme";
import { useStepperContext } from "../../providers/StepperProvider";

const ModalBoxStyle = {
  bgcolor: tertiaryColors.dark,
  borderRadius: "24px",
  width: "560px",
  height: "440px",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column"
};

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSetings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export type DataCheckModalProps = {
  open: boolean;
  fileName: string;
  configName: string;
  jobId: string;
  handleClose: () => void;
  nonMlFlow: boolean;
};

export const DataCheckModal = ({
  open,
  handleClose,
  jobId,
  fileName,
  configName,
  nonMlFlow
}: DataCheckModalProps) => {
  const { data: modelExists } = useGetModelAlreadyExists(configName);
  const { data: pollProgress } = useGetPollProgress(jobId);
  const { isExecuteTabAnalysis } = useStepperContext();

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: ['use-get-poll-progress'] });
    };
  }, []);

  if (pollProgress === 1) {
    if (nonMlFlow) {
      return (
        <Navigate
          to={`/non-ml-flow-results?fileName=${fileName}&configName=${configName}`}
        />
      );
    }
    if (modelExists) {
      return (
        <Navigate
          to={`/results?fileName=${fileName}&configName=${configName}`}
        />
      );
    }

    return (
      <Navigate
        to={`/labelling?fileName=${fileName}&configName=${configName}`}
      />
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className='flex justify-center items-center data-check-modal'
    >
      <Box>
        <Box sx={ModalBoxStyle}>
          <Typography
            sx={{ margin: "30px 0 0 20px" }}
            variant="h4"
            component="div"
            fontWeight={50}
          >
            {(isExecuteTabAnalysis || nonMlFlow) && (
              <span data-testid="model-exists-title-evaluation">Evaluating</span>
            )}
            {!isExecuteTabAnalysis && !nonMlFlow && (
              <span data-testid="model-exists-title-training">Predicting</span>
            )}
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
            <Box sx={{ marginRight: "30px" }}>
              <Lottie
                options={lottieOptions}
                height={200}
                width={200}
              />
            </Box>
            <Typography
              sx={{ flexBasis: "70%", fontSize: "16px" }}
            >
              {(isExecuteTabAnalysis || nonMlFlow) && (
                <span data-testid="model-exists-subtitle-evaluation">Results</span>
              )}

              {!isExecuteTabAnalysis && !nonMlFlow && (
                <span data-testid="model-exists-subtitle-training">Predictions</span>
              )} will be available shortly
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "24px", width: '90%' }}>
            <LinearProgress sx={{marginBottom: "20px" }} variant="determinate" value={ (pollProgress ?? 0) * 99 } />
          </Box>

          <Button
            sx={{ justifyContent: "end", margin: "0 16px 16px 0", fontSize: "18px" }}
            variant="text"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
