import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useGetBoltingDataById } from "../../apis/useGetBoltingData";
import { RowViewComponent } from "./rowViewComponent";
import { useStepperContext } from "../../providers/StepperProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DesignItProgress } from "@design-it/react-library";
import { PageContent } from "../../components/PageContent/PageContent";
import { Box } from "@mui/material";
import {
  fetchLabellingTotal,
  resetFeedback,
} from "../../store/labellingSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectCurrentUser } from "../../store/userSlice";
import { apiHelper } from "../../apis/apiHelper";
import { endpoints } from "../../apis/endpoints";
import { ModelConfig, ModelConfigs } from "../AnalysisPage/types";

type BoltingDataDetailPageProps = {
  modelExists?: boolean;
  nonMlFlow?: boolean;
};

export const BoltingDataDetailPage = ({
  modelExists = true,
  nonMlFlow = false,
}: BoltingDataDetailPageProps) => {
  const { id } = useParams();
  const { data, isLoading } = useGetBoltingDataById(id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const totalCount = useAppSelector(state => state.labelling.totalCount);
  const configData = useAppSelector(state => state.user.modelConfigs);
  const configLoadingStatus = useAppSelector(state => state.user.modelConfigLoadingStatus);
  const [modelConfig, setModelConfig] = useState<ModelConfig | undefined>();

  const [searchParams] = useSearchParams();
  const configName = searchParams.get("configName")!;

  const { setShowStepper } = useStepperContext();

  useEffect(() => {
    setShowStepper(false);
  }, [setShowStepper]);

  const fetchInitialData = useCallback(async () => {
    await dispatch(fetchLabellingTotal({ fetch_only_anomaly: modelExists}));

  }, [dispatch, modelExists]);

  useEffect(() => {
    if (currentUser !== null && totalCount === 0) {
      fetchInitialData();
    }
  }, [currentUser, dispatch, fetchInitialData, totalCount]);

  useEffect(() => {
    (async () => {
      const data: ModelConfigs = configData as ModelConfigs;
      setModelConfig(data[configName]);
    })();
  }, [configLoadingStatus]);

  const feedback = useAppSelector(state => state.labelling.feedback);
  const feedbackClassification = useAppSelector(
    state => state.labelling.feedbackClassification
  );

  const sendFeedback = useCallback(async () => {
    if (data) {
      const response = await apiHelper.patch(
        endpoints.UPDATE_BOLTING_DATA_FEEDBACK,
        {
          PartitionKey: data["productId#processId"],
          SortKey: data.createdDate,
          Feedback: feedback,
          Classification: feedbackClassification,
        }
      );

      if (!response["Attributes"]) {
        throw Error("Setting feedback failed");
      }
    }
  }, [data, feedback, feedbackClassification]);

  const currentProgress = +(id || 0);

  const prevLabellingId = useMemo(() => {
    return currentProgress - 1;
  }, [currentProgress]);

  const nextLabellingId = useMemo(() => {
    return currentProgress + 1;
  }, [currentProgress]);

  const sendFeedbackAndNavigate = useCallback(
    async (newId: number) => {
      await sendFeedback();
      dispatch(resetFeedback());
      if (newId === -3) {
        navigate(-1);
      } else {
        navigate(!modelExists ? `/labelling/${newId}?configName=${configName}` : `/results/${newId}?configName=${configName}`, {
          replace: true,
        });
      }
    },
    [dispatch, modelExists, navigate, sendFeedback]
  );

  return (
    <PageContent
      showButtonBar={true}
      backButton={{
        handler: () => sendFeedbackAndNavigate(-3),
        text: "Go back to Overview",
      }}
      prevButton={{
        handler: () => sendFeedbackAndNavigate(prevLabellingId),
        disabled: prevLabellingId === 0,
      }}
      nextButton={{
        handler: () => sendFeedbackAndNavigate(nextLabellingId > totalCount ? -3 : nextLabellingId),
        text: nextLabellingId > totalCount ? "Go back to Overview" : undefined,
      }}
      currentProgress={`${currentProgress}/${totalCount}`}
    >
      {isLoading ? (
        <Box
          className="w-full h-full flex justify-center items-center flex-col"
          data-testid="loading"
        >
          <DesignItProgress indeterminate />
        </Box>
      ) : (
        <Box className="h-full" data-testid="content">
          <RowViewComponent
            modelConfig={modelConfig}
            modelExists={Boolean(modelExists)}
            nonMlFlow={Boolean(nonMlFlow)}
            selectedRow={data}
          />
        </Box>
      )}
    </PageContent>
  );
};
