import {
  ModuleRegistry,
} from "@ag-grid-community/core";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { getAllBoltingData } from "../../apis/useGetBoltingData";

import "../results/resultsTable.scss";
import {
  LabellingTableProps,
  useIdCellRenderer,
  getLabellingTableColDefs,
} from "../../components/table/CustomHeader";
import { Table } from "../../components/shared/Table/Table";
import { useAppSelector } from "../../store/hooks";
import { getBoltingOperationDataSize } from "../../utils/uploadLimitHelpers";

ModuleRegistry.registerModules([InfiniteRowModelModule]);

export const LabellingTable = ({
  onArrowClick,
}: LabellingTableProps) => {
  /**
   * Since something's off with the AgGrid column definitions, we're using any[] here.
   * Need to be refactored to use ColDef[] or ColGroupDef[].
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */

  const idCellRenderer = useIdCellRenderer();

  const colDefs = getLabellingTableColDefs(idCellRenderer, onArrowClick);

  const currentUser = useAppSelector((state) => state.user.currentUser);

  const getRows = async (pageNumber: number, pageSize: number) => {
    const userId = currentUser?.userId || "";
    return getAllBoltingData(userId, {}, pageSize, pageNumber);
  };

  const getTotalInfo = async () => {
    const userId = currentUser?.userId || "";
    return getBoltingOperationDataSize(userId);
  };

  return (
    <div
      className={`table-layout h-full`}
    >
      <Table
        isLoading={false}
        rowsPerPage={10}
        startPage={1}
        colDefs={colDefs}
        fetchGridData={getRows}
        fetchTotalInfo={getTotalInfo}
      />
    </div>
  );
};