import { DesignItTag } from "@design-it/react-library";
import Chip from "@mui/material/Chip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export const AnomalyTagComponent = (props: { value: string }) => {
  if (props.value === "yes") {
    return (
      <DesignItTag
        className="ics-green-light"
        variant="error"
        icon-name="do_not_disturb_on"
        icon-position="first"
      >
        Anomaly
      </DesignItTag>
    );
  }

  if (props.value === "no") {
    return (
      <DesignItTag
        className="ics-green-light"
        variant="success"
        icon-name="check_circle"
        icon-position="first"
      >
        no anomaly
      </DesignItTag>
    );
  }
  return null;
};

export const AnomalyResultsTagComponent = (props: { value: string }) => {
  if (props.value === "yes") {
    return (
      <Chip
        icon={<InfoOutlinedIcon style={{ color: "var(--sys-Error, #FFB4AB)" }} />}
        label="Anomaly"
        sx={{
          fontFamily: 'var(--sys-Display-Medium-Font, "Frutiger LT 45 Light")',
          fontStyle: "normal",
          fontSize: "var(--sys-Body-Medium-Size, 14px)",
          backgroundColor: "transparent",
          color: "var(--sys-Error, #FFB4AB)", // Text color
          height: "24px",
          "& .MuiChip-label": {
            paddingRight: "0px"
          }
        }}
      />
    );
  }
  if (props.value === "no") {
    return (
      <Chip
        icon={<CheckCircleOutlinedIcon style={{ color: "var(--sys-Success, #49CE5D)" }} />}
        label="No anomaly"
        sx={{
          fontFamily: 'var(--sys-Display-Medium-Font, "Frutiger LT 45 Light")',
          fontStyle: "normal",
          fontSize: "var(--sys-Body-Medium-Size, 14px)",
          backgroundColor: "transparent",
          color: "var(--sys-Success, #49CE5D)", // Text color
          height: "24px"
        }}
      />
    );
  }
  return null;
};