/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMemo
} from "react";
import { getAllBoltingData } from "../../apis/useGetBoltingData"; // Assuming this is your API
import "./resultsTable.scss";
import {
  getResultTableColDefs,
  ResultsTableProps,
} from "../../components/table/CustomHeader";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { Table } from "../../components/shared/Table/Table";
import { anomalyCellRenderer, arrowCellRenderer, idCellRenderer } from "../../components/shared/Table/renderers";
import { useAppSelector } from "../../store/hooks";
import { getBoltingOperationDataSize } from "../../utils/uploadLimitHelpers";


ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const ResultsTable = ({
  onArrowClick,
  isLoading,
  hideArrow = false,
  rowsPerPage = 10,
  startPage = 1,
  nonMlFlow = false,
  modelConfig
}: ResultsTableProps) => {

  const colDefs = useMemo(() => {
    const baseColDefs = getResultTableColDefs(
      idCellRenderer,
      anomalyCellRenderer,
      arrowCellRenderer,
      hideArrow,
      nonMlFlow,
      onArrowClick,
      modelConfig
    );

    return baseColDefs;
  }, [hideArrow, nonMlFlow, onArrowClick]);

  const currentUser = useAppSelector(state => state.user.currentUser);

  const getRowsData = async (pageNumber: number, rowsPerPage: number) => {
    return getAllBoltingData(currentUser?.userId || '', {}, rowsPerPage, pageNumber);
  };

  const getTotalInfo = async () => {
    const userId = currentUser?.userId || "";
    return getBoltingOperationDataSize(userId, "yes");
  };

  return (
    <Table
      isLoading={isLoading}
      rowsPerPage={rowsPerPage}
      startPage={startPage}
      colDefs={colDefs}
      fetchGridData={getRowsData}
      fetchTotalInfo={getTotalInfo}
    />
  );
};
