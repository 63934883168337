import "./BoltingDataDetail.scss";
import { AnomalyResultsTagComponent } from "../../components/AnomalyTag/AnomalyTag";

export interface DetailPanelTileProps {
  label: string,
  value: string | number
}

export const DetailPanelTile = ({label , value}: DetailPanelTileProps) => {
  return (
    <div className="detail-view-info-tile">
      <div className="detail-view-info-tile-content">
        <span>{label}</span>
        {
        label === "AI Prediction" ? 
          <AnomalyResultsTagComponent value={value as string} /> : <span className="text-right max-w-[50%]">{value}</span>}
      </div>
    </div>
  );
};