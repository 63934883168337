import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";

interface ClassificationMenuProps {
  selectedClassification: string; // Receive classification from parent
  onClassificationChange: (classification: string) => void;
}

const ClassificationMenu: React.FC<ClassificationMenuProps> = ({
  selectedClassification,
  onClassificationChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    selectedClassification
  );

  useEffect(() => {
    setSelectedValue(selectedClassification); // Update state when prop changes
  }, [selectedClassification]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setSelectedValue(value);
    onClassificationChange(value); // Notify parent of change
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="dropdown-label">Classification</InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown-select"
        value={selectedValue}
        label="Select Option"
        onChange={handleChange}
        className="h-[45px] "
      >
        <MenuItem value="-">-</MenuItem>
        <MenuItem value="Man">Man</MenuItem>
        <MenuItem value="Machine">Machine</MenuItem>
        <MenuItem value="Method">Method</MenuItem>
        <MenuItem value="Material">Material</MenuItem>
      </Select>
      <FormHelperText>
        Man - Errors from human actions, e.g., mishandling or inattention
      </FormHelperText>
      <FormHelperText>
        Machine - Technical issues, e.g., tool wear or insufficient maintenance
      </FormHelperText>
      <FormHelperText>
        Method - Process errors, e.g., inefficient or incorrect procedures.
      </FormHelperText>
      <FormHelperText>
        Material - Errors due to poor material quality or unsuitable material
      </FormHelperText>
    </FormControl>
  );
};

export default ClassificationMenu;
