import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ChartDataPoint {
  x: number;
  y: number;
}

interface ChartDataSet {
  label: string;
  data: ChartDataPoint[];
  borderColor?: string;
  backgroundColor?: string;
  borderWidth: number;
  yAxisID?: string;
}

export interface LineChartConfig {
  labels: number[];
  datasets: ChartDataSet[];
  xTitle: string | string[] | "";
  yTitle: string | string[] | "";
  title: string;
}

interface LineChartProps {
  config: LineChartConfig;
  width?: number | string;
  height?: number | string;
}

const convertDataPoints = (
  dataPoints: ChartDataPoint[]
): { x: number; y: number }[] => {
  return dataPoints.map(point => ({ x: point.x, y: point.y }));
};

const LineChart: React.FC<LineChartProps> = ({
  config,
}) => {
  const { labels, datasets, xTitle, yTitle, title } = config;

  const data: ChartData<"line"> = {
    labels,
    datasets: datasets.map(dataset => ({
      label: dataset.label,
      data: convertDataPoints(dataset.data),
      borderColor: dataset.borderColor || "rgba(75, 192, 192, 1)",
      backgroundColor:
        dataset.backgroundColor || "rgba(75, 192, 192, 0.2)",
      borderWidth: dataset.borderWidth,
      pointStyle: false,
      yAxisID: dataset.yAxisID,
    })),
  };

  const lastDatasetIdx = datasets.length - 1;
  const minY = 0;
  const maxY = Math.max(
    datasets[0].data.reduce(
      (max, point) => (point.y > max ? point.y : max),
      0
    ) * 1.02,
    datasets[lastDatasetIdx].data.reduce(
      (max, point) => (point.y > max ? point.y : max),
      0
    ) * 1.02);
  const minX = datasets[lastDatasetIdx].data.reduce(
    (min, point) => (point.x < min ? point.x : min),
    0
  );
  const maxX = datasets[lastDatasetIdx].data.reduce(
    (max, point) => (point.x > max ? point.x : max),
    0
  );

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "start" as const,
        labels: {
          pointStyle: "circle" as const,
          usePointStyle: true,
        }
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      x: {
        // display: graphType !== 'torqueToAngle',
        type: "linear",
        position: "bottom",
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: Array.isArray(xTitle) ? xTitle[0] : xTitle,
          font: {
            family: "Frutiger LT 45 Light",
            size: 25,
          },
        },
        min: minX,
        max: maxX,
      },
      y: {
        beginAtZero: true,
        // display: graphType !== 'angleToTime',
        grid: {
          display: true,
          color: "#A7ABAF"
        },
        title: {
          display: true,
          text: Array.isArray(yTitle) ? yTitle[0] : yTitle,
          font: {
            family: "Frutiger LT 45 Light",
            size: 25,
          },
        },
        min: minY,
        max: maxY,
      },
    },
  };

  return (
    <Line
      data={data}
      options={options}
    // width={"600px"}
    // height={"800px"}
    // plugins={[plugin]}
    // style={{ minHeight: 0, maxHeight: "100%" }}
    // style={{ maxWidth: "100%" }}
    />
  );
};

export default LineChart;
