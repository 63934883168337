import "./BoltingDataDetail.scss";
import { useResultsContext } from "../../providers/resultsProvider";
import { BoltingData } from "../../apis/types";
import { LabellingView } from "./LabellingView";
import { ResultView } from "./ResultView";
import { ModelConfig } from "../AnalysisPage/types";

interface RowViewComponentProps {
  modelConfig: ModelConfig | undefined;
  modelExists: boolean;
  nonMlFlow: boolean;
  selectedRow?: BoltingData | null;
}

export function RowViewComponent({
  modelConfig,
  modelExists,
  selectedRow,
  nonMlFlow,
}: Readonly<RowViewComponentProps>) {
  const { currentRow } = useResultsContext();
  const rowToUse = selectedRow ?? currentRow;

  if (!rowToUse) {
    return <div>No data available...</div>;
  }

  return (
    <div className="h-full">
      {!modelExists && (<LabellingView rowToUse={rowToUse} nonMlFlow={nonMlFlow} />)}
      {modelExists && (<ResultView modelConfig={modelConfig} rowToUse={rowToUse} modelExists={modelExists} />)}
    </div>
  );
}
