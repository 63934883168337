import "./BoltingDataDetail.scss";
import DetailedGraphView from "../../components/graphView/detailedGraphViewComponent";
import { BoltingData } from "../../apis/types";
import { DetailPanel } from "./DetailPanel";
import { Box } from "@mui/material";
import { ModelConfig } from "../AnalysisPage/types";

interface ResultViewComponentProps {
  rowToUse: BoltingData;
  modelExists: boolean;
  modelConfig: ModelConfig | undefined;
}

export const ResultView = ({ rowToUse, modelExists, modelConfig }: ResultViewComponentProps) => {

  return (
    <div className="flex flex-col h-full gap-[32px]">
      <div className="result-detail-view-header">
        Result details
      </div>
      <div className="result-detail-view-content flex h-full gap-[10px] max-h-[calc(100%-80px)]">
        <Box
          className="p-8 pt-0 grow min-w-0"
          sx={{ background: theme => theme.palette.info.dark, borderRadius: "12px", }}
        >
          {rowToUse && <DetailedGraphView row={rowToUse} />}
        </Box>
        <Box>
          {modelExists && (<DetailPanel data={rowToUse} modelConfig={modelConfig} flow="results" />)}
        </Box>
      </div>
    </div>
  );
};
