/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AgGridReact } from "@ag-grid-community/react";
import "./styles.scss";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { GridApi, ModuleRegistry } from "@ag-grid-community/core";
import { TableProps } from "./types";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const Table = ({
  isLoading,
  rowsPerPage = 10,
  startPage = 1,
  colDefs = [],
  fetchGridData,
  fetchTotalInfo
}: TableProps) => {
  const [currentPage, setCurrentPage] = useState(startPage);
  const [totalRows, setTotalRows] = useState(0);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const pageChangeInProgress = useRef(false);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch the total row count for the grid
  const fetchTotalRows = async () => {
    try {
      const totalData = await fetchTotalInfo();
      setTotalRows(totalData);
    } catch (error) {
      console.error("Error fetching total data:", error);
    }
  };

  useEffect(() => {
    fetchTotalRows();
  }, [gridApi]);

  // Function to fetch data for the current page
  const fetchPageData = async (pageNumber: number) => {
    if (!gridApi) {
      console.error("Grid API is not ready yet.");
      return;
    }

    const data = await fetchGridData(pageNumber, rowsPerPage);

    setRowData(data);
    setCurrentPage(pageNumber);
    setLoading(false);
  };

  const onPaginationChanged = (event: any) => {
    if (!gridApi || pageChangeInProgress.current) return;
    const newPage = event.api.paginationGetCurrentPage() + 1;
    if (newPage !== currentPage) {
      pageChangeInProgress.current = true;
      gridApi.paginationGoToPage(newPage - 1); // -1 because grid uses 0-indexed pages
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    if (gridApi && currentPage && !pageChangeInProgress.current) {
      fetchPageData(currentPage);
    }
  }, [gridApi, currentPage]);

  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      fetchPageData(page);
    }
  };
  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
  }, []);
  const renderContent = () => {
    return (
      <AgGridReact
        loading={loading || isLoading}
        columnDefs={colDefs}
        rowData={rowData}
        rowBuffer={10} // Ensures smooth loading of rows
        rowClass="custom-row"
        domLayout="autoHeight"
        headerHeight={48}
        rowHeight={48}
        paginationPageSize={rowsPerPage}
        cacheBlockSize={rowsPerPage}
        maxConcurrentDatasourceRequests={1}
        maxBlocksInCache={200}
        onGridReady={onGridReady}
        pagination={true}
        onPaginationChanged={onPaginationChanged}
        suppressPaginationPanel={true}
        rowModelType="clientSide"
        overlayLoadingTemplate={`<span class="ag-overlay-loading-center">Loading...</span>`}
        overlayNoRowsTemplate={
          !loading && rowData.length === 0
            ? `<div class="custom-no-rows">No Rows To Show</div>`
            : ""
        }
      />
    );
  };
  return (
    <Box className={`h-full flex flex-col border border-[#A7ABAF] rounded-md overflow-y-auto`}>
      <Box className="ag-theme-material flex-1 border-b">
        <Box className="h-full">
          {renderContent()} {}
        </Box>
      </Box>
      {!loading &&
        rowData.length > 0 && ( // Show pagination controls only if data is loaded
        <Box className="pagination-container p-2">
          <Typography>
            {`Page ${currentPage} of ${Math.ceil(totalRows / rowsPerPage)}`}
          </Typography>

          {/* Back Arrow */}
          <IconButton
            data-testid="back-arrow"
            onClick={() => {
              if (currentPage > 1) {
                const newPage = currentPage - 1;
                handlePageChange(newPage);
              }
            }}
            disabled={currentPage === 1}
          >
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>

          {/* Forward Arrow */}
          <IconButton
            data-testid="forward-arrow"
            onClick={() => {
              if (currentPage < Math.ceil(totalRows / rowsPerPage)) {
                const newPage = currentPage + 1;
                handlePageChange(newPage);
              }
            }}
            disabled={currentPage === Math.ceil(totalRows / rowsPerPage)}
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
