import React, { useState } from "react";
import LineChart from "./lineChart";
import { LineChartConfig } from "./lineChart";
import "./style.scss";

import { Tab, Tabs } from "@mui/material";
import { BoltingData } from "../../apis/types";

const combinedGraph = (
  rowData: BoltingData,
  graphType: string
): LineChartConfig => {
  const angleValues = rowData.angleInDeg?.split(";").map(Number);
  const torqueValues = rowData.torqueInNm?.split(";").map(Number);

  const timeValues = rowData.timeInSec?.split(";").map(Number);

  const medianAngleValues = rowData.median_angle_value?.split(";").map(Number);
  const medianTorqueAngleValues = rowData.median_torque_angle
    ?.split(";")
    .map(Number);
  const medianTimeValues = rowData.median_time_value?.split(";").map(Number);
  const medianTorqueTimeValues = rowData.median_torque_time
    ?.split(";")
    .map(Number);

  const datasets = [];
  if (graphType === "torqueToTime") {
    datasets.push({
      label: "bolting tool",
      data: timeValues.map((time: number, i: number) => ({
        x: time,
        y: torqueValues[i],
      })),
      borderColor: "#FFCC00",
      backgroundColor: "#FFCC00",
      borderWidth: 2,
    });

    if (rowData.median_time_value) {
      // const reconstructedValues = rowData.reconstructedTorque
      //   ?.split(";")
      //   .map(Number);
      datasets.push({
        label: "median",
        data: medianTimeValues.map((time: number, i: number) => ({
          x: medianTimeValues[i],
          y: medianTorqueTimeValues[i],
        })),
        borderColor: "#B076F8",
        backgroundColor: "#B076F8",
        borderWidth: 2,
      });
    }
  }

  if (graphType === "angleToTime") {
    datasets.push({
      label: "Angle",
      data: timeValues.map((time: number, i: number) => ({
        x: time,
        y: angleValues[i],
      })),
      borderColor: "#8AD6B8",
      backgroundColor: "#8AD6B8",
      borderWidth: 2,
    });
  }

  if (graphType === "torqueToAngle") {
    datasets.push({
      label: "bolting tool",
      data: angleValues.map((time: number, i: number) => ({
        x: angleValues[i],
        y: torqueValues[i],
      })),
      borderColor: "#FFCC00",
      backgroundColor: "#FFCC00",
      borderWidth: 2,
    });

    if (rowData.median_angle_value) {
      datasets.push({
        label: "median",
        data: medianAngleValues.map((time: number, i: number) => ({
          x: medianAngleValues[i],
          y: medianTorqueAngleValues[i],
        })),
        borderColor: "#B076F8",
        backgroundColor: "#B076F8",
        borderWidth: 2,
      });
    }
  }

  return {
    labels: timeValues,
    datasets: datasets,
    xTitle: graphType === "torqueToAngle" ?  "Angle[°]" : "Time[s]",
    yTitle: graphType === "angleToTime" ? "Angle[°]" : "Torque[Nm]",
    title: "",
  };
};
interface CombinedGraphProps {
  row: BoltingData;
}

export default function CombinedGraph(props: CombinedGraphProps) {
  const [graphType, setGraphType] = useState<string>("torqueToAngle");

  const config = combinedGraph(props.row, graphType);

  const handleToggle = (type: string) => {
    setGraphType(type);
  };

  return (
    <div className="h-full ">
      <div className="h-[10%] flex items-center justify-start">
        <Tabs value={graphType}>
          <Tab
            sx={{ width: "100px", backgroundColor: "#262640" }}
            onClick={() => handleToggle("torqueToAngle")}
            label="Torque/Angle"
            value="torqueToAngle"
          />
          <Tab
            sx={{ width: "100px", backgroundColor: "#262640" }}
            onClick={() => handleToggle("torqueToTime")}
            label="Torque/Time"
            value="torqueToTime"
          />
          <Tab
            sx={{ width: "100px", backgroundColor: "#262640" }}
            onClick={() => handleToggle("angleToTime")}
            label="Angle/Time"
            value="angleToTime"
          />
        </Tabs>
      </div>
      <div className="max-h-full h-[90%]">
        <LineChart config={config} />
      </div>
    </div>
  );
}
