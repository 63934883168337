import { BoltingData } from "../../apis/types";
import { ModelConfig } from "../AnalysisPage/types";
import "./BoltingDataDetail.scss";
import { DetailPanelRecommendation } from "./DetailPanelRecommendation";
import { DetailPanelFeedback } from "./DetailPanelFeedback";
import { DetailPanelTile } from "./DetailPanelTile";
import defaultRecommendations from "../../assets/recommendations.json";
import classifications from "../../assets/classifications.json";

export interface DetailPremiumComponentProps {
  data: BoltingData;
  modelConfig: ModelConfig | undefined;
  flow: "labelling" | "results";}

export const DetailPremiumComponent = ({ data, modelConfig, flow }: DetailPremiumComponentProps) => {
  const errorClass = data?.classification_prediction;
  const classification = (classifications as {[key: string]: string})[errorClass];
  const configRecommendation = modelConfig?.recommendation ?? "";
  const recommendations = (
    configRecommendation === "" ? defaultRecommendations : configRecommendation
  ) as { [key: string]: { [key: string]: [{ [key: string]: string; }] } };

  return (
    <>
      {flow === "labelling" ? (
        <div className="detail-view-premium-component">
          <DetailPanelFeedback rowToUse={data} />
        </div>
      ) : ( 
        data.classification_prediction && (
          <div className="detail-view-premium-component">
            <DetailPanelTile label="Classification" value={classification} />
            <DetailPanelRecommendation recommendation={recommendations[errorClass]} />
          </div>
        ))}

    </>
  );
};